/* eslint-disable require-await */
export const useAuthFetch = async (url, params) => {
  const cookie = useCookie("auth_token");
  const config = useRuntimeConfig();

  const opts = {
    key: url,
    baseURL: config.public.API_BASE_URL,

    async onRequest ({ options }) {
      options.headers = options.headers || {};

      if (cookie.value) {
        options.headers.set("Authorization", `Bearer ${cookie.value}`);
      }
    },

    // async onRequestError ({ error }) {
    //   console.log(error.message);
    // },

    // async onResponseError ({ response }) {
    //   console.log(response._data.message);
    // },

    async onResponse ({ response }) {
      // Process the response data
      return response._data;
    },

    ...params

  };

  const { data, pending, error, execute } = await useFetch(url, opts);

  if (error.value && error.value.statusCode === 401) {
    // redirect to arc login
    window.location.href = config.public.ARC_URL + "/login";
  }

  const errors = error?._object?.[error?._key]?.data?.errors;
  const mainError = errors?.[0];

  return {
    data, pending, error, execute, errors, mainError
  };
};
